import { useState } from "react";
import pic from "../../img/manWearSmartGlass.png";
import img1 from "../../img/about-2.png";
import img2 from "../../img/about-3.png";
import img3 from "../../img/about-4.png";
import playVideo from "../../img/video.png";
import { Text } from "../common/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import fleet from "../../img/fleet.png";
import ride from "../../img/ride.png";
import fleetIcon from "../../img/fleet-icon.png";
import xIcon from "../../img/x-icon.png";
import rideIcon from "../../img/ride-icon.png";
import x from "../../img/x.png";
import fireworks from "../../img/about-1.png";
import { Link } from "react-router-dom";
import { DesignAndEstimation } from "../designEstimation";
import { DevAndImplementation } from "../devAndImplementation";
import { ProdAndGrowth } from "../prodAndGrowth";
import { InfoDetailSection } from "../common/infoDetailSection";
import { NewsLetter } from "../common/newsLetter";
import Footer from "../common/footer";

const LandingPage = () => {
  const [activeTab, setActiveTab] = useState({
    design: true,
    development: false,
    production: false,
  });
  const switchTab = (tab: string) => () => {
    if (tab === "design") {
      setActiveTab({
        design: true,
        development: false,
        production: false,
      });
    } else if (tab === "production") {
      setActiveTab({
        design: false,
        production: true,
        development: false,
      });
    } else if (tab === "development") {
      setActiveTab({
        production: false,
        design: false,
        development: true,
      });
    }
  };
  return (
    <div>
      <div className="relative">
        <div
          style={{ backgroundImage: `url(${pic})` }}
          className=" bg-no-repeat bg-cover h-96 md:h-long"
        >
          <div className="flex justify-center">
            <div className="bg-gray-800 absolute -bottom-2 h-6 border-gray-800 w-3/4" />
          </div>
          <div className="flex justify-end">
            <img
              src={playVideo}
              alt="play video"
              className="md:w-80 md:h-80 w-40 h-40"
            />
          </div>
        </div>
      </div>
      <div className="my-16 mx-12 flex-col-reverse flex md:flex-row justify-between">
        <div className="md:w-1/2 w-full">
          <h4 className="text-3xl mt-10 md:mt-0 font-anek font-bold mb-6 text-d-grey">
            <p>Innovation</p>
            <p>at its best!</p>
          </h4>
          <Text className="text-justify mb-8">
            We pride ourselves in discovering new trails of success by turning
            ideas, into digital realities to create a solution that is
            customer-centric. Leveraging on our rich technical expertise. We are
            an internal incubator that builds digital products to help solve
            problems in Africa, primarily focused on Blockchain, Artificial
            intelligence and Finance.
          </Text>
          <Link
            to="about"
            className="text-center bg-gray-800 font-anek font-bold p-4 text-white "
          >
            About Naakwu
          </Link>
        </div>
        <div className="relative">
          <div className="absolute w-8 border-red border-r-2 border-b-2 p-4 -top-9 -left-8" />
          <div className="absolute w-8 border-green border-b-2 border-l-2 -top-9 -right-8 p-4" />
          <div className="absolute w-8 border-t-2 border-l-2 -right-8 -bottom-9 p-4" />
          <div className="absolute w-8 -left-8 border-r-2 border-t-2 -bottom-9 p-4"></div>
          <img
            src={fireworks}
            alt="fireworks"
            className="h-[509px] w-[420px]"
          />
        </div>
      </div>
      <div className="flex md:flex-row mx-12 flex-col justify-around my-16 mt-36">
        <div className="relative md:w-1/4 w-full">
          <img src={fleet} alt="fleet" />
          <img
            src={fleetIcon}
            className="absolute top-4 left-4 w-[14px] h-[24px]"
            alt="fleet-icon"
          />
          <div className="flex justify-center">
            <p className="absolute bottom-0 font-anek font-bold">
              <span className="text-white">Naakwu</span>{" "}
              <span className="text-[#E32681] ml-2">fleet</span>
            </p>
            <div className="bg-[#E32681] absolute -bottom-1 w-4/5 h-2" />
          </div>
        </div>
        <div className="relative w-full md:w-1/4 my-10 md:my-0">
          <img src={ride} alt="ride" />
          <img
            src={rideIcon}
            className="absolute top-4 left-4 w-[14px] h-[24px]"
            alt="ride-icon"
          />
          <div className="flex justify-center">
            <p className="absolute bottom-0 left-28 font-anek font-bold">
              <span className="text-white">Naakwu</span>{" "}
              <span className="text-[#57CC99] ml-2">ride</span>
            </p>
            <div className="bg-[#57CC99] absolute -bottom-1 w-4/5 h-2" />
          </div>
        </div>
        <div className="relative w-full md:w-1/4">
          <img src={x} alt="x-img" />
          <img
            src={xIcon}
            className="absolute top-4 left-4 w-[14px] h-[24px]"
            alt="x-icon"
          />
          <div className="flex justify-center">
            <p className="absolute bottom-0 left-32 font-anek font-bold">
              <span className="text-white">Naakwu</span>{" "}
              <span className="text-[#E09F3E] ml-2">x</span>
            </p>
            <div className="bg-[#E09F3E] absolute -bottom-1 w-4/5 h-2" />
          </div>
        </div>
      </div>

      <div className="flex md:flex-row flex-col mx-12 justify-around md:pl-48">
        <div className="md:w-2/3 w-full mb-12 md:mb-0">
          <h4 className="font-bold font-anek text-2xl text-d-grey">
            <p>Our</p>
            <p>Solution</p>
          </h4>
          <Text>
            We are an internal incubator that builds digital products to help
            solve problems in Africa, primarily focused on Blockchain,
            Artificial intelligence and Finance.
          </Text>
        </div>
        <div>
          <div className="flex md:flex-row flex-col">
            <div className="w-full md:w-1/6" />
            <div className="w-full md:w-1/2">
              <h4 className="relative">
                <FontAwesomeIcon
                  icon={icon({ name: "plus", style: "solid" })}
                  fontSize={20}
                  className="align-middle absolute top-1 -left-8"
                  color="#4D4D4D"
                />
                <span className="font-anek text-xl font-bold text-[#4D4D4D]">
                  Blockchain
                </span>
              </h4>
              <Text>
                Explore the world of scalable and secure decentralized
                applications with our end-to-end blockchain development
                services.
              </Text>
            </div>
          </div>
          <div className="flex md:flex-row flex-col my-12">
            <div className="w-full md:w-1/6" />
            <div className="w-full md:w-1/2">
              <h4 className="relative">
                <FontAwesomeIcon
                  icon={icon({ name: "plus", style: "solid" })}
                  fontSize={20}
                  className="align-middle absolute top-1 -left-8"
                  color="#4D4D4D"
                />
                <span className="font-anek text-xl font-bold text-[#4D4D4D]">
                  Finance
                </span>
              </h4>
              <Text>
                We offer our in-depth fintech expertise, first-class
                specialists, and effective customer-oriented approach to help
                our users on their way to digital transformation.
              </Text>
            </div>
          </div>

          <div className="flex md:flex-row flex-col">
            <div className="w-full md:w-1/6" />
            <div className="w-full md:w-1/2">
              <h4 className="relative">
                <FontAwesomeIcon
                  icon={icon({ name: "plus", style: "solid" })}
                  fontSize={20}
                  className="align-middle absolute top-1 -left-8"
                  color="#4D4D4D"
                />
                <span className="font-anek text-xl font-bold text-[#4D4D4D]">
                  Artificial Intelligence
                </span>
              </h4>
              <Text>
                We conceptualize and implement AI-driven software solutions into
                business operations to increase productivity and operational
                efficiencies.
              </Text>
            </div>
          </div>
          <div className="flex md:flex-row flex-col mt-12">
            <div className="w-full md:w-1/6" />
            <Link
              to="solution"
              className="font-anek font-bold p-2 text-center border-2 border-d-grey w-1/2 md:w-1/5"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>

      <div className="mx-12">
        <h4 className="font-anek text-d-grey my-20 mb-10 text-2xl text-center font-bold">
          Our Process
        </h4>
        <div className="flex flex-row border-gray-300 border-t-1 md:overflow-x-hidden overflow-x-scroll md:justify-between scrollbar-hide">
          <p
            onClick={switchTab("design")}
            className={`border-t-4 cursor-pointer font-anek ${
              activeTab.design
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } pt-4 text-normal whitespace-nowrap`}
          >
            Design and Estimation
          </p>
          <p
            onClick={switchTab("development")}
            className={`${
              activeTab.development
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } font-anek mx-12 md:mx-0 text-normal pt-4 border-t-4 cursor-pointer whitespace-nowrap`}
          >
            Development and Implementation
          </p>
          <p
            onClick={switchTab("production")}
            className={`${
              activeTab.production
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } font-anek text-normal pt-4 border-t-4 cursor-pointer whitespace-nowrap`}
          >
            Production and Growth
          </p>
        </div>
        <>
          {activeTab.design ? (
            <DesignAndEstimation />
          ) : activeTab.development ? (
            <DevAndImplementation />
          ) : activeTab.production ? (
            <ProdAndGrowth />
          ) : null}
        </>
      </div>
      <InfoDetailSection
        dataObject={[
          {
            img: img1,
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
          {
            img: img2,
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design", "development"],
          },
          {
            img: img3,
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
        ]}
      />
      <NewsLetter />
      <Footer />
    </div>
  );
};
export default LandingPage;
