import { Text } from "./common/text";

export const ProdAndGrowth = () => (
  <div className="flex md:flex-row flex-col my-10 md:mx-12">
    <div className="relative md:w-2/6 w-full mb-8">
      <h2 className="text-9xl font-bold text-[#E6E6E6] hidden md:block">3</h2>
      <h4 className="font-anek font-bold text-2xl top-12 left-6 md:absolute w-full text-d-grey">
        <p>Production and</p>
        <p>Growth</p>
      </h4>
    </div>
    <div className="w-full ">
      <Text>
        We live in a magical modern era where many problems have already been
        solved for us. We focus on the core product as much as possible and
        outsource operations as much as possible to external services
      </Text>
      <div className="flex flex-col md:w-3/5 mt-4">
        <div className="flex md:flex-row mt-8">
          <div className="h-[8px] w-[8px] mt-[12px] bg-d-grey mr-6" />
          <Text>Product Roadmaps</Text>
        </div>
        <div className="flex md:flex-row mt-8">
          <div className="h-[8px] w-[8px] mt-[12px] bg-d-grey mr-6" />
          <Text>Design New Features</Text>
        </div>
        <div className="flex md:flex-row mt-8">
          <div className="h-[8px] w-[8px] mt-[12px] bg-d-grey mr-6" />
          <Text>Develop New Features</Text>
        </div>
      </div>
    </div>
  </div>
);
