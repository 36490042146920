import lightUp from "../../img/about-1.png";
import img1 from "../../img/about-2.png";
import img2 from "../../img/about-3.png";
import img3 from "../../img/about-4.png";
import { Text } from "../common/text";
import Footer from "../common/footer";
import { NewsLetter } from "../common/newsLetter";
import { InfoDetailSection } from "../common/infoDetailSection";

const About = () => {
  return (
    <>
      <p className="md:mx-16 mx-4 pt-8 font-anek mb-16 text-d-grey text-2xl font-bold text-center">
        Our Story
      </p>
      <div className="md:mx-16 mx-4 border-gray-300 border-t-1 " />
      <section className="md:mx-16 mx-4 flex justify-between mt-12 md:flex-row flex-col">
        <div id="finance" className="flex justify-start flex-col md:flex-row">
          <h4 className="text-left md:ml-12 font-anek text-4xl mb-8 font-normal leading-10 w-full">
            <p>Innovation at</p>
            <p>its best!</p>
          </h4>
          <Text className="text-justify md:ml-12">
            We pride ourselves in discovering new trails of success by turning
            ideas into digital realities to create a solution that is
            customer-centric. Leveraging on our rich technical expertise, Naakwu
            story began in 2019, with people like you in mind. We are an
            internal incubator that builds digital products to help solve
            problems in Africa, primarily focused on Blockchain, Artificial
            intelligence and Finance. We are a team of engineers, designers and
            business strategists who are passionate about creating products that
            have a global impact.
          </Text>
        </div>
      </section>
      <section className="md:mx-16 mx-4 mt-4 mb-12">
        <img src={lightUp} alt="light-up img" />
        <Text className="mt-16">
          We help our users adapt and excel in the digital era by designing and
          developing innovative solutions across web, mobile, and emerging
          technologies. Our proven track record spans from developing and
          implementing projects that solve the problems of our ever-growing
          customer base. Our team and development process are custom-tailored to
          constantly invest in research and development in order to improve our
          existing capabilities.
        </Text>
        <h4 className="font-anek text-2xl text-left">
          <p>“</p>
          <p>
            Our aim is to build a world-class solution company using
            revolutionary design-driven best business approaches.
          </p>
          <p>“</p>
        </h4>
        <Text className="text-grey-faint">
          Naakwu offers digital solutions by bringing financial technology
          closer to everyone.
        </Text>
      </section>
      <InfoDetailSection
        dataObject={[
          {
            img: img1,
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
          {
            img: img2,
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design", "development"],
          },
          {
            img: img3,
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
        ]}
      />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default About;
