import macbook from "../../img/macbook.png";
import { Text } from "../common/text";
import Footer from "../common/footer";
import { NewsLetter } from "../common/newsLetter";
import { InfoDetailSection } from "../common/infoDetailSection";

const Blog = () => {
  return (
    <>
      <div className="md:mx-16 mx-4 pt-8">
        <h4 className="text-left font-anek text-4xl mb-8 font-normal">
          How Apple’s M1 chips make macOS development much less costly
        </h4>
        <img src={macbook} alt="light-up img" />
      </div>
      <section className="md:mx-16 mx-4 flex justify-between mt-12 md:flex-row flex-col flex-nowrap">
        <p className=" font-anek text-normal w-3/6 text-left mb-8 font-normal border-t-2">
          June 06, 2022
        </p>
        <div className="md:ml-12 text-left">
          <p>
            What do you think about the phrase “beautiful design”? You like it,
            don’t care or does it make you wince?
          </p>
          <Text className="text-justify">
            For many, “beautiful” is a derogatory term, whether because it
            doesn’t go with personality, or like with some of the designers,
            they sometimes become coarsened by their professions and find this
            word trite and shallow. People have been arguing about the notion of
            beauty throughout their history and their history and aren’t likely
            to stop doing it any time soon. Beauty vs function in design is one
            of the most perpetual controversial subjects ever. Some say that the
            design should be beautiful, other insist that its main goal is to be
            functional.
          </Text>
        </div>
      </section>
      <section className="md:mx-16 mx-4 mt-4 mb-12">
        <h4 className="font-anek text-2xl text-left">
          <p>“</p>
          <p>
            Aesthetics is a particular theory or conception of beauty or art: a
            particular taste for or approach to what is pleasing to the senses
            and especially sight.
          </p>
          <p>“</p>
        </h4>
        <Text className="text-grey-faint text-left md:ml-12">
          For many, “beautiful” is a derogatory term, whether because it doesn’t
          go with personality, or like with some of the designers, they
          sometimes become coarsened by their professions and find this word
          trite and shallow. People have been arguing about the notion of beauty
          throughout their history and their history and aren’t likely to stop
          doing it any time soon. Beauty vs function in design is one of the
          most perpetual controversial subjects ever. Some say that the design
          should be beautiful, other insist that its main goal is to be
          functional..
        </Text>
      </section>
      <InfoDetailSection
        dataObject={[
          {
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
          {
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design", "development"],
          },
          {
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
        ]}
      />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Blog;
