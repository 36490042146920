import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./common/navbar";
import About from "./screens/about";
import Blog from "./screens/blog";
import BlogList from "./screens/blogs";
import ContactUs from "./screens/contact";
import LandingPage from "./screens/landingPage";
import Solution from "./screens/solution";
import ScrollToTop from "./scrollToTop";

export const Paths = () => {
  const [activeTab, setActiveTab] = useState("solution");
  const [isOpen, setIsOpen] = useState(false);
  const onClickHandler = (value: string) => () => {
    setActiveTab(value);
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Navbar
        activeTab={activeTab}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClick={(value: string) => onClickHandler(value)}
      />
      <ScrollToTop>
        <Routes>
          <Route element={<LandingPage />} path="/" />
          <Route element={<BlogList />} path="blog" />
          <Route element={<Blog />} path="blog/single" />
          <Route element={<About />} path="about" />
          <Route element={<ContactUs />} path="contact" />
          <Route element={<Solution />} path="solution" />
        </Routes>
      </ScrollToTop>
    </>
  );
};
