import { Text } from "./common/text";

export const DevAndImplementation = () => (
  <div className="flex md:flex-row flex-col my-10 md:mx-12">
    <div className="relative w-full mb-8">
      <h2 className="text-9xl font-bold text-[#E6E6E6] hidden md:block">2</h2>
      <h4 className="font-anek font-bold text-2xl top-12 left-6 md:absolute w-full text-d-grey">
        <p>Development and</p>
        <p>Implementation</p>
      </h4>
    </div>
    <div className="">
      <Text>
        Our development practices were first detailed in Kent Beck's classic
        Extreme Programming Explained: Embrace Change and in Gerald Weinberg's
        The Psychology of Computer Programming. We've tried its practices and
        found that using most of the practices most of the time improves the
        quality of our work and happiness of our team.
      </Text>
      <div className="flex flex-col md:w-3/5 mt-12">
        <div className="flex md:flex-row mt-8">
          <div className="h-[8px] w-[8px] mt-[12px] bg-d-grey mr-6" />
          <Text>Pre-Development Planning</Text>
        </div>
        <div className="flex md:flex-row mt-8">
          <div className="h-[8px] w-[8px] mt-[12px] bg-d-grey mr-6" />
          <Text>Active Development</Text>
        </div>
      </div>
    </div>
  </div>
);
