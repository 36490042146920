import { Text } from "./text";

export const NewsLetter = () => (
  <div className="md:mx-16 mx-4 mt-20 text-left md:w-1/2 mb-20">
    <h5 className="font-anek pt-4 border-t-4 cursor-pointer border-white text-4xl mb-6">
      our newsletter
    </h5>
    <Text className="text-gray-800">
      Our newsletter is aimed at helping Tech enthusiasts with freshly curated
      ideas to be more productive, stay inspired and think critically.
    </Text>
    <div className="mt-12 flex flex-row relative mb-8 bg-gray-300 items-center">
      <input
        className="bg-gray-300 py-4 placeholder-slate-600 text-normal font-mont font-thin md:w-9/12 w-3/5 pl-2 outline-none text-sm align-middle"
        placeholder="miracle@naakwu.ng"
      />
      <button className="bg-gray-800 align-middle px-4 mx-2 py-1 text-normal text-white absolute right-10.5">
        Subscribe
      </button>
    </div>
  </div>
);
