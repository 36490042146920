import { useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../../img/story-pic1.png";
import img2 from "../../img/story-pick2.png";
import img3 from "../../img/story-pic3.png";
import img4 from "../../img/story-pic4.png";
import img5 from "../../img/story-pic5.png";
import { Text } from "../common/text";
import Footer from "../common/footer";

const BlogList = () => {
  const [activeTab, setActiveTab] = useState({
    latest: true,
    design: false,
    development: false,
    management: false,
    marketing: false,
  });
  const switchTab = (tab: string) => () => {
    if (tab === "latest") {
      setActiveTab({
        latest: true,
        design: false,
        development: false,
        management: false,
        marketing: false,
      });
    } else if (tab === "design") {
      setActiveTab({
        latest: false,
        design: true,
        development: false,
        management: false,
        marketing: false,
      });
    } else if (tab === "development") {
      setActiveTab({
        latest: false,
        design: false,
        development: true,
        management: false,
        marketing: false,
      });
    } else if (tab === "management") {
      setActiveTab({
        latest: false,
        design: false,
        development: false,
        management: true,
        marketing: false,
      });
    } else if (tab === "marketing") {
      setActiveTab({
        latest: false,
        design: false,
        development: false,
        management: false,
        marketing: true,
      });
    }
  };
  return (
    <>
      <div className="md:mx-12">
        <p className="md:mx-16 pt-8 font-anek mb-16 text-d-grey font-bold text-center text-2xl">
          This is where we tell stories
        </p>
        <div className="mx-4 flex flex-row border-gray-300 border-t-1 md:overflow-x-hidden overflow-x-scroll justify-between md:justify-start scrollbar-hide capitalize">
          <Link
            reloadDocument
            onClick={switchTab("latest")}
            to="#latest"
            className={`border-t-4 cursor-pointer font-anek ${
              activeTab.latest
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } pt-4 whitespace-nowrap text-normal`}
          >
            latest
          </Link>
          <Link
            onClick={switchTab("design")}
            reloadDocument
            to="#design"
            className={`${
              activeTab.design
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } font-anek whitespace-nowrap mx-12 text-normal pt-4 border-t-4 cursor-pointer`}
          >
            design
          </Link>
          <Link
            reloadDocument
            to="#development"
            onClick={switchTab("development")}
            className={`${
              activeTab.development
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } font-anek text-normal pt-4 border-t-4 cursor-pointer whitespace-nowrap mr-12`}
          >
            development
          </Link>
          <Link
            reloadDocument
            to="#management"
            onClick={switchTab("management")}
            className={`${
              activeTab.management
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } font-anek text-normal pt-4 border-t-4 cursor-pointer whitespace-nowrap mr-12`}
          >
            management
          </Link>
          <Link
            reloadDocument
            to="#marketing"
            onClick={switchTab("marketing")}
            className={`${
              activeTab.marketing
                ? "border-gray-800"
                : "border-white text-grey-faint"
            } font-anek text-normal pt-4 border-t-4 cursor-pointer whitespace-nowrap`}
          >
            marketing
          </Link>
        </div>
        <div className="flex md:flex-row flex-col justify-between mx-4 my-10 mb-16">
          <div className="md:w-[25%] w-full md:mb-0 mb-8">
            <Link to="#">
              <div>
                <img src={img1} alt="KO" />
              </div>
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                E-Learning app design and how to make it better
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                Kids and adults are becoming more and more tech-savy, especially
                the kids who are used to all kinds of gadgets from a very small
                age.
              </Text>
              <div>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # design
                </p>
              </div>
            </Link>
            <Link to="#" className="my-10">
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                How to integrate combine with swift UI to make better apps
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                Kids and adults are becoming more and more tech-savy, especially
                the kids who are used to all kinds of gadgets from a very small
                age.
              </Text>
              <div>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # design
                </p>
              </div>
            </Link>
            <Link to="#">
              <div>
                <img src={img2} alt="KO" />
              </div>
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                E-Learning app design and how to make it better
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                Kids and adults are becoming more and more tech-savy, especially
                the kids who are used to all kinds of gadgets from a very small
                age.
              </Text>
              <div>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # design
                </p>
              </div>
            </Link>
          </div>
          <div className="md:w-[35%] w-full md:mb-0 mb-8">
            <Link to="single">
              <div>
                <img src={img3} alt="KO" />
              </div>
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                How Apple’s M1 chips make macOS development much less costly
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                The macOS market share is much smaller than the iOS one. There
                are many more iPhones out there, they dominate Apple’s revenues
                so that’s where most of their attention goes.
              </Text>
              <div className="mb-16">
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # management
                </p>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # development
                </p>
              </div>
            </Link>
            <Link to="#" className="mt-10">
              <div>
                <img src={img4} alt="KO" />
              </div>
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                E-Learning app design and how to make it better
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                Kids and adults are becoming more and more tech-savy, especially
                the kids who are used to all kinds of gadgets from a very small
                age.
              </Text>
              <div>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # design
                </p>
              </div>
            </Link>
          </div>
          <div className="md:w-[25%] w-full md:mb-0 mb-8">
            <Link to="#">
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                E-Learning app design and how to make it better
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                Kids and adults are becoming more and more tech-savy, especially
                the kids who are used to all kinds of gadgets from a very small
                age.
              </Text>
              <div>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # design
                </p>
              </div>
            </Link>
            <Link to="#" className="my-10">
              <div>
                <img src={img5} alt="KO" />
              </div>
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                E-Learning app design and how to make it better
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                Kids and adults are becoming more and more tech-savy, especially
                the kids who are used to all kinds of gadgets from a very small
                age.
              </Text>
              <div>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # design
                </p>
              </div>
            </Link>
            <Link to="#">
              <h6
                className={`font-anek font-semibold border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
              >
                E-Learning app design and how to make it better
              </h6>
              <Text className="text-justify text-nak-txt-color text-normal">
                Kids and adults are becoming more and more tech-savy, especially
                the kids who are used to all kinds of gadgets from a very small
                age.
              </Text>
              <div>
                <p
                  className={`uppercase font-anek mt-2 text-left text-sm font-bold text-nak-txt-color`}
                >
                  # design
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogList;
