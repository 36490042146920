import { useState } from "react";
import { Link } from "react-router-dom";
import finance from "../../img/finance.png";
import blockchain from "../../img/blockchain.png";
import AI from "../../img/ai.png";
import { Text } from "../common/text";
import Footer from "../common/footer";
import { NewsLetter } from "../common/newsLetter";
import { InfoDetailSection } from "../common/infoDetailSection";

const Solution = () => {
  const [activeTab, setActiveTab] = useState({
    finance: true,
    blockchain: false,
    ai: false,
  });
  const switchTab = (tab: string) => () => {
    if (tab === "finance") {
      setActiveTab({
        finance: true,
        blockchain: false,
        ai: false,
      });
    } else if (tab === "blockchain") {
      setActiveTab({
        finance: false,
        blockchain: true,
        ai: false,
      });
    } else if (tab === "ai") {
      setActiveTab({
        finance: false,
        blockchain: false,
        ai: true,
      });
    }
  };
  return (
    <>
      <p className="md:mx-16 pt-8 mx-4 font-anek mb-16 text-d-grey font-bold text-center text-2xl">
        Our Solution
      </p>
      <div className="md:mx-16 mx-4 flex flex-row border-gray-300 border-t-1 md:overflow-x-hidden overflow-x-scroll justify-between md:justify-start scrollbar-hide">
        <Link
          reloadDocument
          onClick={switchTab("finance")}
          to="#finance"
          className={`border-t-4 cursor-pointer font-anek ${
            activeTab.finance
              ? "border-gray-800"
              : "border-white text-grey-faint"
          } pt-4 text-normal`}
        >
          Finance
        </Link>
        <Link
          onClick={switchTab("blockchain")}
          reloadDocument
          to="#blockchain"
          className={`${
            activeTab.blockchain
              ? "border-gray-800"
              : "border-white text-grey-faint"
          } font-anek mx-12 text-normal pt-4 border-t-4 cursor-pointer `}
        >
          Blockchain
        </Link>
        <Link
          reloadDocument
          to="#ai"
          onClick={switchTab("ai")}
          className={`${
            activeTab.ai ? "border-gray-800" : "border-white text-grey-faint"
          } font-anek text-normal pt-4 border-t-4 cursor-pointer whitespace-nowrap`}
        >
          Artificial Intelligence
        </Link>
      </div>
      <section className="md:mx-16 mx-4 flex justify-between mt-20 md:flex-row flex-col ">
        <img src={finance} alt="finance-img" />
        <div id="finance" className="flex justify-start flex-col">
          <h4 className="text-left md:ml-12 font-anek text-4xl mt-12 md:mt-0 mb-8 font-normal">
            Finance
          </h4>
          <Text className="text-justify md:ml-12 leading-8">
            From simple mobile payment apps to complex enterprise-grade
            financial platforms, we offer our in-depth fintech expertise,
            first-class specialists, and effective customer-oriented approach to
            help our users on their way to digital transformation. Naakwu is an
            innovator that creates and supports digital banking platforms. We
            provide robust and secure digital fintech products powered by the
            latest technologies. We offer fintech app development services and
            dedicated teams for a variety of businesses like fintech start-ups,
            traditional financial and non-financial companies.
          </Text>
        </div>
      </section>
      <section className="md:mx-16 mx-4 flex flex-col md:flex-row-reverse justify-between my-24">
        <img src={blockchain} alt="blockchain-img" />
        <div id="blockchain" className="flex justify-start flex-col">
          <h4 className="text-left md:mr-12 font-anek text-4xl mt-12 md:mt-0 mb-8 font-normal">
            Blockchain
          </h4>
          <Text className="text-justify md:mr-12">
            Explore the world of scalable and secure decentralized applications
            with our end-to-end blockchain development services. We leverage the
            power of blockchain technologies and solutions to achieve next-level
            transparency and automation in business processes. Our blockchain
            solutions are tailored to specific business use cases via feature
            integration, system modifications, and implementations. We
            capitalize the blockchain technology in launching reliable and
            secure decentralized solutions for business automation. Blockchain
            developers at Naakwu have a strong track record for blockchain
            application development and consulting services for industries such
            as Healthcare, Gaming, E-commerce, and Financial Services.
          </Text>
        </div>
      </section>
      <section className="md:mx-16 mx-4 flex flex-col md:flex-row justify-between mb-28">
        <img src={AI} alt="ai-img" />
        <div id="ai" className="flex justify-start flex-col">
          <h4 className="text-left md:ml-12 font-anek text-4xl mt-12 md:mt-0 mb-8 font-normal">
            <p>Artificial</p>
            <p>Intelligence</p>
          </h4>
          <Text className="text-justify md:ml-12">
            Naakwu development services create custom artificial intelligence
            solutions that provide insights and automate processes for
            businesses. We conceptualize and implement AI-driven software
            solutions into business operations to increase productivity and
            operational efficiencies, make faster business decisions based on
            outputs from cognitive technologies, and contribute to long-term
            business continuity and efficiency. With AI, your network becomes
            easier to operate. Artificial intelligence machines can also analyze
            large amounts of data within a few seconds. They usually take less
            time to make decisions as long as they have relevant information.
          </Text>
        </div>
      </section>
      <InfoDetailSection
        dataObject={[
          {
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
          {
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design", "development"],
          },
          {
            title: "How to integrate combine with swift UI to make better apps",
            text: "Kids and adults are becoming more and more tech-savy, especially the kids who are used to all kinds of gadgets from a very small age.",
            tags: ["design"],
          },
        ]}
      />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Solution;
