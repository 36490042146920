import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import logo from "../../img/logo2.png";

const Footer = () => (
  <footer className="bg-gray-800 flex flex-row md:justify-between justify-center py-16">
    <div className="text-white font-anek font-bold ml-12">
      <span>Naakwu</span>
      <img src={logo} className="inline" alt="logo img" />
    </div>
    <div className="flex-row mr-12 md:flex hidden justify-around w-1/4">
      <FontAwesomeIcon icon={brands("github")} size={"xl"} color="#fff" />
      <FontAwesomeIcon icon={brands("facebook")} size={"xl"} color="#fff" />
      <FontAwesomeIcon icon={brands("twitter")} size={"xl"} color="#fff" />
      <FontAwesomeIcon icon={brands("linkedin")} size={"xl"} color="#fff" />
      <FontAwesomeIcon icon={brands("instagram")} size={"xl"} color="#fff" />
    </div>
  </footer>
);

export default Footer;
