import { useEffect, FC } from "react";
import { useLocation } from "react-router";

type Props = {
  children: any;
};
const ScrollToTop: FC<Props> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/solution") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
