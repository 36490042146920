const ContactUs = () => {
  return (
    <div className="pt-12 flex md:flex-row flex-col justify-between md:mx-16 mx-4">
      <div className="w-full md:w-1/2 mr-16 text-left">
        <h4 className="font-anek font-bold text-4xl">Contact us</h4>
        <p className="font-mont font-thin text-normal leading-8 text-nak-txt-color">
          We are passionate about innovation, brillant ideas and the execution
          that brings it all together in one beautiful experience. If you are
          too, you can send us an email
        </p>
      </div>
      <form className="bg-gray-100 p-4 md:p-10 mt-8 md:mt-0 w-full md:w-3/4 mb-8 flex flex-col">
        <div className="flex flex-col text-left">
          <label className="font-anek text-normal mb-2">Name</label>
          <input className="bg-gray-200 p-2 outline-none px-4 text-normal font-thin" />
        </div>
        <div className="flex flex-col text-left my-12">
          <label className="font-anek text-normal mb-2">E-mail</label>
          <input className="bg-gray-200 p-2 outline-none px-4 text-normal font-thin" />
        </div>
        <div className="flex flex-col text-left">
          <label className="font-anek text-normal mb-2">
            How can we be of help?
          </label>
          <textarea
            rows={6}
            className="bg-gray-200 outline-none px-4 text-normal font-thin"
          />
        </div>
        <button className="border-gray-700 p-2 border-2 text-lg mt-8 self-end">
          Get in touch
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
