import { FC } from "react";

type Props = {
  children: any;
  className?: string;
};

export const Text: FC<Props> = ({ children, className }) => (
  <p
    className={`font-mont text-normal font-thin text-nak-txt-color leading-8 ${
      className || ""
    }`}
  >
    {children}
  </p>
);
