import { FC } from "react";
import { Text } from "./text";

type Props = {
  dataObject: Array<{
    img?: any;
    title: string;
    text: string;
    tags: Array<string>;
  }>;
};

export const InfoDetailSection: FC<Props> = ({ dataObject }) => (
  <div className="bg-gray-100 px-8 flex flex-col md:flex-row py-24 justify-around h-3/4 md:overflow-scroll">
    {dataObject.map((item) => (
      <div className="md:w-1/4 w-full">
        {item.img ? (
          <img src={item.img} className="my-4" alt="feature-img" />
        ) : null}
        <h6
          className={`font-anek font-semibold ${
            item.img ? "" : "border-t-2"
          }  border-b-2 py-6 border-gray-400 text-justify text-lg mb-8`}
        >
          {item.title}
        </h6>
        <Text className="text-justify text-nak-txt-color text-normal">
          {item.text}
        </Text>
        <div className="mb-12">
          {item.tags.map((tag, index) => (
            <p
              className={`uppercase font-anek ${
                index === 0 ? "mt-6" : "mt-2"
              } text-left text-sm font-bold text-nak-txt-color`}
            >
              {`# ${tag}`}
            </p>
          ))}
        </div>
      </div>
    ))}
  </div>
);
