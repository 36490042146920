/* eslint-disable react-hooks/exhaustive-deps */
import { FC, MouseEventHandler, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

type Props = {
  activeTab: string;
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  onClick: (value: string) => MouseEventHandler<HTMLElement> | undefined;
};

const getWindowSize = (): { innerWidth: number; innerHeight: number } => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const Navbar: FC<Props> = ({ onClick, activeTab, isOpen, setIsOpen }) => {
  const [, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div>
      <div
        className={`flex flex-1 flex-row justify-between font-mont font-normal md:px-16 px-4 ${
          !isMobile ? "bg-white" : isOpen ? "bg-d-grey" : "bg-white"
        }`}
      >
        <Link
          onClick={isMobile ? () => null : onClick("landing")}
          className="bg-d-grey py-2 md:py-6 px-10 font-anek text-2xl font-bold text-white"
          to="/"
        >
          <h1>Naakwu</h1>
        </Link>
        <button
          className="md:hidden block py-6 outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <FontAwesomeIcon
              icon={icon({ name: "close", style: "solid" })}
              fontSize={25}
              color="#fff"
            />
          ) : (
            <>
              <div className="border-2 border-black w-6 bg-black rounded-md mb-2" />
              <div className="border-2 border-black bg-black w-6 rounded-md" />
            </>
          )}
        </button>
        <div className="hidden md:flex flex-row justify-around py-6 w-1/3">
          <Link
            to="solution"
            onClick={onClick("solution")}
            className={`${
              activeTab === "solution"
                ? "border-b-2 border-d-grey"
                : "text-grey-faint"
            }  cursor-pointer`}
          >
            Our solution
          </Link>
          <Link
            to="blog"
            onClick={onClick("blog")}
            className={`${
              activeTab === "blog"
                ? "border-b-2 border-d-grey"
                : "text-grey-faint"
            }  cursor-pointer`}
          >
            Our blog
          </Link>
          <Link
            to="about"
            onClick={onClick("about")}
            className={`${
              activeTab === "about"
                ? "border-b-2 border-d-grey"
                : "text-grey-faint"
            }  cursor-pointer`}
          >
            About us
          </Link>
        </div>
        <Link
          onClick={onClick("contact")}
          to="contact"
          className={`hidden md:block border-2 font-mont text text-base font-bold border-gray-700 my-4 py-2 px-8 ${
            activeTab === "contact"
              ? "text-white bg-d-grey"
              : "text-d-grey bg-white"
          } `}
        >
          <h4 className="font-anek text-lg">Contact us</h4>
        </Link>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden bg-d-grey" id="mobile-menu">
            <div
              ref={ref}
              className="text-white pt-2 flex flex-col pb-3 sm:px-3 px-14"
            >
              <Link
                to="solution"
                onClick={onClick("solution")}
                className="cursor-pointer text-white font-mont mb-12"
              >
                Our Solution
              </Link>
              <Link
                to="blog"
                onClick={onClick("blog")}
                className="cursor-pointer text-white font-mont mb-12"
              >
                Our Blog
              </Link>
              <Link
                to="about"
                onClick={onClick("about")}
                className="cursor-pointer text-white font-mont mb-12"
              >
                About Us
              </Link>
              <Link
                to="contact"
                onClick={onClick("contact")}
                className="cursor-pointer text-white font-mont mb-12"
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default Navbar;
