import { Text } from "./common/text";

export const DesignAndEstimation = () => (
  <div className="flex md:flex-row flex-col my-10 md:mx-12">
    <div className="relative">
      <h2 className="text-9xl font-bold text-[#E6E6E6] hidden md:block">1</h2>
      <h4 className="font-anek font-bold text-2xl top-12 left-6 md:absolute w-52 text-d-grey">
        <p>Design and</p>
        <p>Estimation</p>
      </h4>
    </div>
    <div className="">
      <Text className="md:ml-32">
        Our human-centered design process is grounded in the principles of
        Design Thinking. It's the driving force behind our projects and teams.
        Designers keep this process alive by helping our teams understand user
        problems and needs. Together, we collaboratively and continuously
        ideate, build, and learn.
      </Text>
      <div className="flex md:flex-row flex-wrap justify-between md:w-3/5 md:ml-32 mt-12">
        <div className="flex md:flex-row md:w-1/2 md:mt-0">
          <span className="h-[8px] w-[8px] mt-[12px] align-middle bg-d-grey" />
          <Text className="ml-4 ">Design Discovery</Text>
        </div>
        <div className="flex md:flex-row md:w-1/2 mt-8 md:mt-0">
          <span className="h-[8px] w-[8px] mt-[12px] bg-d-grey mr-6" />
          <Text>User Interface Design</Text>
        </div>
        <div className="flex md:flex-row md:w-1/2 mt-8">
          <span className="h-[8px] w-[8px] mt-[12px] align-middle bg-d-grey" />
          <Text className="ml-4">User Experience Design</Text>
        </div>
        <div className="flex md:flex-row md:w-1/2 mt-8">
          <span className="h-[8px] w-[8px] mt-[12px] bg-d-grey mr-6" />
          <Text>Prototype and Estimation</Text>
        </div>
      </div>
    </div>
  </div>
);
